<template>
  <div class="reset_balance_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <div class="top">
        <p class="name">{{ $t('common.field.accNum') }}</p>
        <span class="number">{{ number }}</span>
      </div>
      <div class="form_box">
        <el-form :model="formLabelAlign" ref="resetBalanceForm" :rules="resetBalanceRules">
          <InputForm
            name="resetBalTo"
            :label="$t('common.field.resetBalTo')"
            v-model.trim="formLabelAlign.resetBalTo"
          ></InputForm>
          <el-button class="purple_button" @click="resetBalConfirmClick" data-testid="confirm">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="white_button" @click="disableResetBalanceVisible(false)" data-testid="cancel">
            {{ $t('common.button.cancel') }}
          </el-button>
        </el-form>
      </div>
    </vDialog>
  </div>
</template>

<script>
import vDialog from './vDialog';
import { apiupdate_balance_demo } from '@/resource';

export default {
  name: 'resetBalance',
  props: {
    resetBalVisible: Boolean,
    number: Number,
    resetBalConfirmVisible: Boolean
  },
  components: { vDialog },
  data() {
    const validateResetBalTo = (rule, value, callback) => {
      this.formLabelAlign.resetBalToVerification = false;
      if (value == '' || !checkNumber(value) || value < 1 || value > 1000000) {
        callback(new Error(this.$t('common.formValidation.resetBalAmt')));
      } else {
        this.formLabelAlign.resetBalToVerification = true;
      }
    };
    const checkNumber = value => {
      let reg = new RegExp('^[0-9]+$');
      return reg.test(value);
    };
    return {
      visible: false,
      formLabelAlign: {
        resetBalTo: '',
        resetBalToVerification: false
      },
      resetBalanceRules: {
        resetBalTo: [{ required: true, validator: validateResetBalTo, trigger: 'blur' }]
      }
    };
  },
  watch: {
    resetBalVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableResetBalanceVisible(bool);
    }
  },
  methods: {
    resetBalConfirmClick() {
      if (this.formLabelAlign.resetBalToVerification) {
        this.updateBalanceDemo();
      }
    },
    disableResetBalanceVisible(bool) {
      this.$refs.resetBalanceForm.resetFields();
      this.formLabelAlign.resetBalToVerification = false;
      this.$emit('update:resetBalVisible', bool);
    },
    updateBalanceDemo() {
      apiupdate_balance_demo({
        metaTraderLogin: this.number,
        targetBalance: this.formLabelAlign.resetBalTo
      })
        .then(resp => {
          this.$emit('resetTable');
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.disableResetBalanceVisible(false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/resetBalance.scss';
</style>
