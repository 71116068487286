<template>
  <Home>
    <template slot="alerts">
      <div class="content_title">
        <h2>{{ $t('menu.homeDemo') }}</h2>
        <p></p>
      </div>
    </template>
    <div class="table_list_box" v-if="checkRegisterStep">
      <div class="table_item_box">
        <p class="title">MetaTrader4</p>
        <router-link :to="{ name: 'register' }" class="btn purple_button" data-testid="liveButton4">
          {{ $t('openDemoAdditAcc.liveButton') }}
        </router-link>
        <div class="table_box">
          <el-table>
            <el-table-column :label="$t('common.column.accNum')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.column.type')" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.currency')" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.equity')" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.column.status')" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.resetMT4PW')" min-width="120"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="table_list_box">
      <div class="table_item_box">
        <p class="title">MetaTrader4</p>
        <router-link
          :to="{ name: 'openDemoAccount', query: { p: 4 } }"
          class="btn purple_button"
          data-testid="openDemoAdditAcc4"
        >
          {{ $t('openDemoAdditAcc.button') }}
        </router-link>
        <div class="table_box">
          <el-table :data="mt4AccountTableData">
            <el-table-column :label="$t('common.column.accNum')" prop="accNum" min-width="120"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('common.column.server')"
              prop="server"
              min-width="180"
            ></el-table-column>
            <el-table-column :label="$t('common.column.type')" min-width="120">
              <template slot-scope="scope">
                {{ $config.accountTypeMaps[scope.row.accMt4Type] }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.currency')" prop="currency" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.equity')" min-width="120">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="140">
              <template slot-scope="scope">
                <div v-if="scope.row.status !== 'expired'">
                  {{ scope.row.leverage }} : 1
                  <div class="icon" v-if="scope.row.leverage">
                    <a>
                      <img
                        src="@/assets/images/select_down.png"
                        alt
                        @click.stop.prevent="leverageDemoClick(scope.row)"
                      />
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.status')" prop="status" min-width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'active'" class="green">{{ scope.row.status | accStatus }}</div>
                <div v-if="scope.row.status == 'expired'" class="red">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.resetBal')" prop="status" min-width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="reset_balance">
                  <a @click.stop.prevent="resetBalClick(scope.row)">$</a>
                </div>
                <div v-if="scope.row.status === 'expired'" class="red">-</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.resetMT4PW')" min-width="120">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status === 'active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  data-testid="resetMT4PW"
                >
                  <img class="lock_icon" src="@/assets/images/header/logout_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <template slot="components">
      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :showTNC="false"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="getDemo()"
        method="demo"
      ></ChangeLeverage>
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog_body" v-if="leverageChangeSubmitSuccess">
          <img src="@/assets/images/dialog_true.png" alt />
          <p
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitFail">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitReject">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitTimeOut">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageDefault', { supportEmail: this.$config.getSupportEmail() })"></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Password
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Password>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <img src="@/assets/images/dialog_true.png" alt />
        <div v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- 点击 RESET BALANCE 开始 -->
      <ResetBalance :resetBalVisible.sync="resetBalVisible" :number="number" @resetTable="getDemo"> </ResetBalance>
      <!-- 点击 RESET BALANCE 结束 -->
    </template>
  </Home>
</template>

<script>
import Home from '@/components/template/Home';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import Common from '@/components/home/Common';
import { apiHomeGet_demo_accs, apiReq_reset_mt4_password } from '@/resource';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import Password from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ResetBalance from '@/components/home/ResetBalance';

export default {
  components: { Home, ChangeLeverage, Common, Password, ChangePassword, ResetBalance },
  mixins: [changeLeverageMixins],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      mt4AccountTableData: [],
      allMetaDemoAccounts: [],
      passwordVisible: false,
      changePasswordVisible: false,
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      resetBalVisible: false,
      number: null,
      isDemo: true
    };
  },
  mounted() {
    this.getDemo();
  },
  methods: {
    getDemo() {
      apiHomeGet_demo_accs().then(resp => {
        this.allMetaDemoAccounts = resp.data.data || [];
        this.formatAccounts();
      });
    },
    formatAccounts() {
      this.mt4AccountTableData = [];
      this.allMetaDemoAccounts.map(m => {
        if (m.platform == '4') this.mt4AccountTableData.push(m);
      });
    },
    handleLock(index, row) {
      this.passwordVisible = true;
      this.number = parseInt(row.accNum);
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    resetBalClick(row) {
      this.resetBalVisible = true;
      this.number = parseInt(row.accNum);
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    }
  },
  computed: {
    checkRegisterStep() {
      return this.$store.state.common.registerStep < 5;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
